import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../../../../Button"

import Shield from "../../../../../assets/img/shield.svg"

import "./intro.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        HeroImage: file(relativePath: { eq: "industries/isv.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        OrganizationsMobile: file(
          relativePath: { eq: "industries/organizations_mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Organizations: file(
          relativePath: { eq: "industries/organizations.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="isvPage__intro">
        <Img
          className="isvPage__heroImage"
          fluid={data.HeroImage.childImageSharp.fluid}
          alt="Hero image"
        />
        <div className="container">
          <h1>
            <span>
              Full cloud software
              <br /> licensing platform
            </span>{" "}
            so you can build your application
          </h1>
          <p>
            Building an in-house licensing server is time consuming, expensive
            to maintain, and is not the reason people love your software. From
            the reliability, the functionality, and the pricing, LicenseSpring
            was built with Small software vendors in mind to remove the
            headaches from operating a homegrown license server.
          </p>
          <h2>Reduce Development time:</h2>
          <ul>
            <li>No need to set up your own license server</li>
            <li>Add a trial mode with just a few method calls to our SDK</li>
            <li>
              Offline license check fall-back part of our standard solution
            </li>
            <li>
              Generate and dispatch licenses dynamically through your favorite
              E-commerce platform
            </li>
          </ul>
          <Button
            label="Open trial account"
            size="big"
            shadow
            color="blue"
            disclaimer
            to="/get-started/"
          />
          <div>
            <p>
              <img src={Shield} alt="Shield icon" /> Trusted by hundreds of
              organizations including:
            </p>
          </div>
          <Img
            className="organizationsLogos organizationsLogos--mobile"
            fluid={data.OrganizationsMobile.childImageSharp.fluid}
            alt="Organizations logos"
          />
          <Img
            className="organizationsLogos"
            fluid={data.Organizations.childImageSharp.fluid}
            alt="Organizations logos"
          />
        </div>
      </section>
    )}
  />
)
