import React from 'react'

import Layout from '../../../components/Layout'
import SEO from "../../../components/Seo"
import ThirdParty from '../../../components/ThirdParty'
import Features from '../../../components/Features'
import Apps from '../../../components/Apps'
import BottomBlock from '../../../components/BottomBlock'

import Intro from '../../../components/Pages/Industries/Isv/Intro'
import Flexible from '../../../components/Pages/Industries/Isv/Flexible'

export default ({location}) =>
    <Layout className="page page--industry isvPage">
        <SEO
            title="LicenseSpring: Licensing for Start-ups and and ISVs"
            description="Enforcing the license agreements means more revenue for your business. Learn how you can manage software entitlements for your standalone applications"
            path={location.pathname}
        />
        <Intro />
        <div className="container">
            <Apps 
                title="Cross-platform development:" 
                content="We offer several SDKs which are designed to be used in projects which run on multiple environments, including the latest builds from .NET standard, multiple C++ binaries, as well as Swift-Objective-C. You may also use our web hooks to activate and check a license from any environment." />
            
        </div>
        <Flexible />
        <div className="container">
            <Features />
            
        </div>
        <ThirdParty />
        <BottomBlock content="Grow your software business by adding a robust, flexible licensing system to the applications you distribute to your end-users, trusted by hundreds of organizations." />
    </Layout>