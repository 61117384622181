import React from 'react'

import './flexible.scss'

export default () =>
   
            <section className="isvPage__flexible">
                <div className="container">
                    <h2>Flexible Licensing Models:</h2>
                    <p>You need a licensing solution that is flexible enough to enforce the software agreement that makes the most sense for your organization. LicenseSpring allows you to configure your software to work as described in your license agreement:</p>
                    <ul>
                        <li>Activate, deactivate, and edit the entitlements for any newly issued or existing licenses</li>
                        <li>Set how many machines can share a license, and track license usage through our online platform</li>
                        <li>Implement any license model, from the most straightforward, to the most sophisticated licensing models:</li>
                    </ul>
                    
                   
                </div>
            </section>